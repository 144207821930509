import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

//Imgs
import OutsourcingAbout01 from "../../../Global/images/outsourcingAbout01.jpg";
import OutsourcingAbout02 from "../../../Global/images/outsourcingAbout02.jpg";

const StyledAboutBlock = styled(NanoFlex)`
  height: auto;
  margin-bottom: 56px;
  padding: 0 10%;
  .aboutBlockWrapper {
    .leftAsideAbout {
      width: 60%;
      background: linear-gradient(180deg, #ffffff 40%, #e7f3fe 40%);
      .imgWrapper {
        width: 50%;
        height: 430px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .listAbout {
        padding: 56px;
        .itemListAbout {
          margin-bottom: 24px;
          text-align: right;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .rightAsideAbout {
      width: 40%;
      height: auto;
      .infoContainer {
        padding: 32px;
        height: auto;
        h4 {
          text-transform: uppercase;
          margin-bottom: 8px;
        }
      }
      .imgWrapper {
        /* height: 485px; */
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1240px) {
    padding: 0 5%;
  }
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
    padding: 0;
    .aboutBlockWrapper {
      .leftAsideAbout {
        .imgWrapper {
          width: 80%;
        }
      }
    }
  }

  @media only screen and (max-width: 740px) {
    .aboutBlockWrapper {
      flex-direction: column;
      .leftAsideAbout {
        order: 2;
        width: 100%;
        .imgWrapper {
          width: 65%;
        }
        .listAbout {
          padding: 32px;
          .itemListAbout {
            text-align: left;
            justify-content: flex-start;
            margin-bottom: 16px;
          }
        }
      }
      .rightAsideAbout {
        order: 1;
        width: 100%;
        .infoContainer {
          padding-top: 0;
        }
        .imgWrapper {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 460px) {
    .aboutBlockWrapper {
      .leftAsideAbout {
        .imgWrapper {
          width: 100%;
          height: 45vh;
        }
        .listAbout {
          .itemListAbout {
            h5 {
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
      .rightAsideAbout {
        .infoContainer {
          padding-bottom: 16px;
        }
      }
    }
  }
`;

export default class AboutBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledAboutBlock className="aboutBlock">
        <NanoFlex className="aboutBlockWrapper" alignItems="stretch">
          <NanoFlex className="leftAsideAbout" flexDirection="column">
            <NanoFlex className="imgWrapper" justifyContent="flex-start" alignItems="flex-start">
              <img src={OutsourcingAbout01} alt="Outsourcing" />
            </NanoFlex>
            <NanoFlex className="listAbout" flexDirection="column">
              <NanoFlex className="itemListAbout" justifyContent="flex-end">
                <Type.h5>{this.translation.services.outsourcing.aboutBlock.list.item1}</Type.h5>
              </NanoFlex>
              <NanoFlex className="itemListAbout" justifyContent="flex-end">
                <Type.h5>{this.translation.services.outsourcing.aboutBlock.list.item2}</Type.h5>
              </NanoFlex>
              <NanoFlex className="itemListAbout" justifyContent="flex-end">
                <Type.h5>{this.translation.services.outsourcing.aboutBlock.list.item3}</Type.h5>
              </NanoFlex>
              <NanoFlex className="itemListAbout" justifyContent="flex-end">
                <Type.h5>{this.translation.services.outsourcing.aboutBlock.list.item4}</Type.h5>
              </NanoFlex>
              <NanoFlex className="itemListAbout" justifyContent="flex-end">
                <Type.h5>{this.translation.services.outsourcing.aboutBlock.list.item5}</Type.h5>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="rightAsideAbout" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <Type.h4>
                <b>{this.translation.services.outsourcing.aboutBlock.info.title}</b>
              </Type.h4>
              <Type.p>{this.translation.services.outsourcing.aboutBlock.info.article}</Type.p>
            </NanoFlex>
            <NanoFlex className="imgWrapper" justifyContent="flex-start" alignItems="flex-start">
              <img src={OutsourcingAbout02} alt="Outsourcing" />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledAboutBlock>
    );
  }
}
