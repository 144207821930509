import React from "react";
import styled from "styled-components";
import BaseComponent from "../BaseComponent.js";
import NanoFlex from "../Global/NanoFlex.js";
import Type from "../Global/Typography.js";
import { validateEmailFormat } from "../Global/tools.js";
import candidatesService from "../../services/CandidatesService.js";

// Componets
import Input from "../Forms/Input.js";
import Textarea from "../Forms/Textarea.js";
import MainButton from "../Buttons/MainButton.js";

const StyledModalContactCompany = styled(NanoFlex)`
  width: 400px;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  .contactFormWrapper {
    padding: 24px;
    .contactFormContainer {
      .contactFormTitle {
        margin-bottom: 16px;
        height: auto;
        h4 {
          color: ${(props) => props.theme.color.colorsPalette.blue};
          text-transform: uppercase;
        }
      }
      .contactForm {
        .formContainer {
          max-height: 62vh;
          overflow: auto;
          margin-bottom: 16px;
          padding: 6px;
        }
        .formWrapper {
          margin-bottom: 16px;
          height: auto;
          .TextAreaContainer {
            .TextAreaWrapper {
              textarea {
                height: 72px;
              }
            }
          }
          .closeBtn {
            height: auto;
            cursor: pointer;
            width: auto;
            margin-top: 8px;
            h6 {
              text-transform: uppercase;
            }
            &:hover {
              h6 {
                color: ${(props) => props.theme.color.colorsPalette.red};
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 440px) {
    width: 90vw;
  }
`;

export class ModalContactCompany extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      name: "",
      phone: "",
      email: "",
      company: "",
      companyNif: "",
      observations: "",
      loading: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  isSendDisabled = () => {
    /*Required Name, Phone, Email, Company and Company Nif */
    return this.state.name?.trim() == "" || this.state.phone?.trim() == "" || this.state.email?.trim() == "" || validateEmailFormat(this.state.email?.trim()) == false || this.state.company?.trim() == "" || this.state.companyNif?.trim() == "";
  };

  sendEmail = () => {
    this.setState({ loading: true }, () => {
      candidatesService
        .contactUsCompanyEmail({
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          company: this.state.company,
          companyNif: this.state.companyNif,
          observations: this.state.observations,
          candidateIds: this.props.selectedCandidates,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.setState(this.getDefaultState(), () => this.props.nextAction());
        });
    });
  };

  render() {
    return (
      <StyledModalContactCompany className="modal">
        <NanoFlex className="contactFormWrapper" justifyContent="flex-start">
          <NanoFlex className="contactFormContainer" flexDirection="column">
            <NanoFlex className="contactFormTitle">
              <Type.h4>
                <b>{this.translation.global.contactUs}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="contactForm" flexDirection="column">
              <NanoFlex className="formContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start">
                {/* NAME */}
                <NanoFlex className="formWrapper">
                  <Input title={this.translation.contactForm.name} placeholder={this.translation.contactForm.namePlaceholder} defaultValue={this.state.name} onChangeAction={(v) => this.setState({ name: v })} />
                </NanoFlex>
                {/* PHONE */}
                <NanoFlex className="formWrapper">
                  <Input title={this.translation.contactForm.phone} placeholder={this.translation.contactForm.phonePlaceholder} numeric defaultValue={this.state.phone} onChangeAction={(v) => this.setState({ phone: v })} />
                </NanoFlex>
                {/* EMAIL */}
                <NanoFlex className="formWrapper">
                  <Input title={this.translation.contactForm.email} placeholder={this.translation.contactForm.emailPlaceholder} defaultValue={this.state.email} onChangeAction={(v) => this.setState({ email: v })} />
                </NanoFlex>
                {/* COMPANY */}
                <NanoFlex className="formWrapper">
                  <Input title={this.translation.contactForm.company} placeholder={this.translation.contactForm.companyPlaceholder} defaultValue={this.state.company} onChangeAction={(v) => this.setState({ company: v })} />
                </NanoFlex>
                {/* NIF COMPANY */}
                <NanoFlex className="formWrapper">
                  <Input title={this.translation.contactForm.nifCompany} placeholder={this.translation.contactForm.NifCompanyPlaceholder} numeric defaultValue={this.state.companyNif} onChangeAction={(v) => this.setState({ companyNif: v })} />
                </NanoFlex>
                {/* OBSERVAÇÕES */}
                <NanoFlex className="formWrapper">
                  <Textarea title={this.translation.contactForm.observations} placeholder={this.translation.contactForm.observationsPlaceholder} defaultValue={this.state.observations} onChangeAction={(v) => this.setState({ observations: v })} />
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="formWrapper" flexDirection="column">
                <MainButton label={this.translation.global.send} disabled={this.isSendDisabled() || this.state.loading} clickAction={this.sendEmail} />
                <NanoFlex className="closeBtn" onClick={this.props.cancelAction}>
                  <Type.h6>{this.translation.global.cancel}</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledModalContactCompany>
    );
  }
}
