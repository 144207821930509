import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import { ReactComponent as ElevusLogo } from "./../../Global/images/elevusLogo.svg";

// Componets
import Input from "../../Forms/Input.js";
import Textarea from "../../Forms/Textarea.js";
import MainButton from "../../Buttons/MainButton.js";
import { CTAButton } from "../../Buttons/CTAButton.js";

const StyledGuidelines = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.colorsPalette.grey};
  padding-top: 30px;
  .guidelineBlock {
    height: auto;
    padding: 62px;
  }
`;

export class Guidelines extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledGuidelines flexDirection={"column"} justifyContent={"flex-start"}>
        {/*GUIDELINES */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"}>
          <Type.h1>
            <b>GUIDELINES</b>
          </Type.h1>
          {/* <ElevusLogo /> */}
        </NanoFlex>

        {/* TYPOGRAPHY */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Type.h1>
            <b>H1</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h1>
          <Type.h2>
            <b>H2</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h2>
          <Type.h3>
            <b>H3</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h3>
          <Type.h4>
            <b>H4</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h4>
          <Type.h5>
            <b>H5</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h5>
          <Type.h6>
            <b>H6</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h6>
          <Type.p>
            <b>P</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.p>
        </NanoFlex>

        {/* FORMS */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Input />
          <Textarea />
        </NanoFlex>

        {/* BUTTONS */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <MainButton label="Enviar" />
          <CTAButton />
          <CTAButton white />
        </NanoFlex>
      </StyledGuidelines>
    );
  }
}
