import Type from "./../../Global/Typography.js";
import React, { lazy } from "react";
import styled from "styled-components";
import BaseComponent from "./../../BaseComponent.js";

// Components
import NanoFlex from "./../../Global/NanoFlex.js";
import Input from "../../Forms/Input.js";
import RecruitmentToggle from "../../Buttons/RecruitmentToggle.js";

import CandidateItem from "./RecruitmentComponents/CandidateItem.js";
import RecruitmentItem from "./RecruitmentComponents/RecruitmentItem.js";

import CandidateItemDetail from "./RecruitmentComponents/CandidateItemDetail.js";
import RecruitmentItemDetail from "./RecruitmentComponents/RecruitmentItemDetail.js";

import Modal from "../../Modals/Modal.js";
import { ModalContactCompany } from "../../Modals/ModalContactCompany.js";

import Loading from "../../Animations/Loading.js";
import LoaderElevus from "./../../Global/images/loader-elevus.gif";

//Imgs
import RecruitmentHeader from "./../../Global/images/recruitmentHeaderNoBG.svg";
import SimpleButton from "../../Buttons/SimpleButton.js";

//Services
import candidatesService from "../../../services/CandidatesService.js";
import recruitmentRequestService from "../../../services/RecruitmentRequestService.js";

const StyledRecruitment = styled(NanoFlex)`
  gap: 54px;
  flex-direction: column;

  .elevusLoading {
    height: 100px;

    img {
      height: 100px;
      width: 200px;
    }
  }

  .recruitmentHeaderWrapper {
    background-color: ${(props) => props.theme.color.brandingColors.brandBlue};
    height: auto;

    .recruitmentHeader {
      height: 60vh;

      .headerInfo {
        width: 60%;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        background-color: ${(props) => props.theme.color.brandingColors.brandBlue};

        padding: 35px;

        .headerSearchBox {
          height: max-content;
          max-height: max-content;
          gap: 30px;

          .inpuWrapper {
            width: 100%;

            .inputContainer {
              width: 100%;
            }
          }
        }

        .headerTitle {
          height: auto;

          h2 {
            color: ${(props) => props.theme.color.main.white};
            font-size: 60px;
            line-height: 80px;
          }

          b {
            color: ${(props) => props.theme.color.main.white};
          }
        }

        .headerArticle {
          margin: 32px 0;
          height: auto;

          h5 {
            color: ${(props) => props.theme.color.main.white};
          }
        }

        .ctaContainer {
          height: auto;
        }
      }

      .headerImg {
        overflow: hidden;
        order: 1;
        height: 100%;
        width: 40%;

        img {
          height: 100%;
          width: auto;
          object-fit: cover;
        }
      }
    }
  }

  .recruitmentSearchBlock {
    gap: 15px;
    margin-bottom: 54px;

    .recruitmentToggleWrapper {
      width: 100%;
      padding: 0 35px;
    }

    .opportunitiesNumberWrapper {
      gap: 5px;
      width: 100%;
      padding: 0 35px;

      .offerNumberWrapper {
        gap: 5px;
      }

      .cleanSearchFilter {
        p {
          cursor: pointer;
          user-select: none;
          transition: all 0.4s ease-in-out;

          &:hover {
            text-decoration: underline;
            color: ${(props) => props.theme.color.colorsPalette.red};
          }
        }
      }

      h3 {
        font-size: 16px;
        text-transform: uppercase;
      }

      .coloredText {
        color: ${(props) => (props.isCandidate ? props.theme.color.colorsPalette.lightOrange : props.theme.color.main.blue)};
      }
    }

    .recruitmentDetail {
      width: 100%;
      overflow: hidden;
      height: auto;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};

      .listFullWrapper {
        overflow: hidden;
        width: 29%;

        .listFullScroll {
          overflow-y: auto;
          scroll-behavior: smooth;
          max-height: 80vh;
          gap: 25px;
          padding: 20px 50px 20px 35px;

          .singleItemSmall {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0;
            background-color: ${(props) => props.theme.color.main.white};

            .asideInfo {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }

            .arrowWrapper {
              height: fit-content;
            }
          }
        }

        .contactSelectedWrapper {
          padding: 20px 0;
          background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
          height: fit-content;

          .button {
            width: 100%;
            padding: 0 35px;

            .buttonWrapper {
              width: 100%;
              background-color: ${(props) => (props.isContactUsActive ? props.theme.color.main.blue : props.theme.color.colorsPalette.opacityBlue)};
              pointer-events: ${(props) => (props.isContactUsActive ? "auto" : "none")};
            }
          }
        }
      }
    }

    .toggleRecruitmentBlock {
      padding: 15px 0;
    }

    .searchBoxesRecruitment {
      display: flex;
      flex-wrap: wrap;
      white-space: pre-wrap;
      align-items: flex-end;
      justify-content: center;
      padding: 0 35px;
      gap: 15px;
      column-gap: 20px;

      .textAreaWrapper {
        flex: 1 1 30%;

        .button {
          margin: 0;
          width: 100%;

          .buttonWrapper {
            width: 100%;
            background-color: ${(props) => (props.isCandidate ? props.theme.color.main.blue : props.theme.color.colorsPalette.lightOrange)};
          }
        }

        &.disabled {
          .button {
            .buttonWrapper {
              background-color: ${(props) => (props.isCandidate ? props.theme.color.colorsPalette.opacityBlue : props.theme.color.colorsPalette.opacityOrange)};
              pointer-events: none;
            }
          }
        }
      }
    }
    .seeAllBtnWrap {
      width: 100%;

      .button {
        .buttonWrapper {
          background-color: ${(props) => (props.isCandidate ? props.theme.color.colorsPalette.lightOrange : props.theme.color.main.blue)};
        }
      }
    }

    .recruitmentListFlex {
      white-space: pre-wrap;
      column-gap: 30px;
      row-gap: 20px;
      padding: 20px 35px;
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1200px) {
    .recruitmentSearchBlock {
      .recruitmentListFlex {
        gap: 25px;
      }
    }
  }

  @media only screen and (max-width: 1074px) {
    gap: 30px;

    .headerArticle {
      display: none;
    }
    .recruitmentHeaderWrapper {
      position: relative;

      .recruitmentHeader {
        flex-direction: column;
        height: 68vh;

        .headerInfo {
          order: 2;
          width: 100%;
          padding: 16px;
          gap: 25px;

          .headerTitle {
            height: auto;
            z-index: 3;

            h2 {
              font-size: 46px;
              line-height: 50px;

              b {
                text-shadow: 5px 7px 11px rgba(0, 0, 0, 0.38);
              }
            }
          }

          .headerSearchBox {
            z-index: 3;
            gap: 10px;
          }
        }

        .headerImg {
          order: 1;
          width: 100%;
          height: 65vh;

          img {
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    .recruitmentSearchBlock {
      .recruitmentToggleWrapper {
        width: 100%;
        padding: 0 16px;
      }

      .recruitmentListFlex {
        gap: 15px;
        padding: 0 16px;
        justify-content: space-evenly;
      }

      .searchBoxesRecruitment {
        padding: 0 16px;
      }

      .opportunitiesNumberWrapper {
        padding: 0 16px;
      }

      .recruitmentDetail {
        flex-direction: column;
        height: max-content;
        padding: 0 16px;
        background-color: ${(props) => props.theme.color.main.white};

        .listFullWrapper {
          width: 100%;
          background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};

          .listFullScroll {
            flex-direction: row;
            overflow-y: hidden;
            overflow-x: auto;
            padding: 15px;

            .singleItemSmall {
              min-width: 200px;
              max-width: 264px;
            }
          }

          .contactSelectedWrapper {
            padding: 15px 0 0 0;
          }
        }

        .detailContentFullWrapper {
          width: 100%;
          background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
          padding: 20px 15px;

          .openDetailContent {
            .detailContentHeader {
              flex-direction: column;
              gap: 15px;

              .detailContentTitleWrapper {
                gap: 15px;
                padding: 15px 25px;
                order: 2;
                width: 100%;
                border-radius: 0;

                .detailTitleHeader {
                  h4 {
                    font-size: 23px;
                  }
                }
              }

              .iconShareWrapper {
                order: 1;
                width: 100%;
              }
            }

            .detailFullContent {
              max-height: 200px;

              p {
                line-height: 25px;
                letter-spacing: 0.4px;
              }
            }
          }

          .applyBtnWrapper {
            padding: 10px 25px;
            flex-direction: column;
            width: 100%;
            align-items: center;

            .releaseDateWrapper {
              justify-content: center;
            }

            .button {
              justify-content: center;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 530px) {
    gap: 30px;

    .recruitmentHeaderWrapper {
      .recruitmentHeader {
        .headerInfo {
          order: 2;
          width: 100%;
          gap: 20px;

          .headerTitle {
            h2 {
              font-size: 41px;
              line-height: 41px;
            }
          }

          .headerSearchBox {
            flex-direction: column;
          }
        }
      }
    }

    .recruitmentTypeInput {
      .radioCandidate,
      .radioCompany {
        .nameType {
          font-size: 13px;
        }
      }
    }

    .recruitmentSearchBlock {
      .searchBoxesRecruitment {
        .textAreaWrapper {
          flex: 1 1 100%;
          width: 100%;
        }
      }

      .recruitmentListFlex {
        gap: 15px;
      }

      .opportunitiesNumberWrapper {
        flex-direction: column;
        justify-content: center;

        .offerNumberWrapper {
          order: 2;
          justify-content: center;

          h3 {
            line-height: 39px;
          }
        }

        .cleanSearchFilter {
          order: 1;
          justify-content: center;
        }
      }
    }
  }
`;

export default class Recruitment extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();

    this.requestId = 0;

    this._totalRowsRef = React.createRef();
  }

  getDefaultState = () => {
    return {
      isCandidate: true,
      ...this.getDefaultResultsState(),
    };
  };

  getDefaultResultsState = () => {
    return {
      loading: false,
      totalRows: null,
      results: [],
      page: 1,
      pageSize: 16,
      selectedId: null,
      filter: null,
      selectedIds: [],
      function: "",
      location: "",
      scrollToSelected: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();

    this.getResults();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isCandidate !== this.state.isCandidate || prevState.page !== this.state.page || JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter)) {
      this.getResults();
    }

    if (JSON.stringify(prevState.results) !== JSON.stringify(this.state.results)) {
      this.setState({ selectedIds: [] });
    }

    if (this._totalRowsRef?.current && prevState.selectedId !== this.state.selectedId && this.state.selectedId) {
      this._totalRowsRef.current.scrollIntoView();
    }

    if (prevState.scrollToSelected !== this.state.scrollToSelected && this.state.scrollToSelected) {
      this.setState({ scrollToSelected: false });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getResults = () => {
    this.setState({ loading: true }, () => {
      this.requestId++;
      let currentRequestId = this.requestId;
      let params = {
        ...this.state.filter,
        ...{
          page: this.state.page,
          pageSize: this.state.pageSize,
        },
      };
      (this.state.isCandidate ? recruitmentRequestService.getRecruitmentRequestWebsiteSharingJobs(params) : candidatesService.getWebsiteCandidates(params))
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          if (this.requestId === currentRequestId) {
            this.setState({
              totalRows: (data ?? [])[0]?.totalRows ?? 0,
              results: [...this.state.results, ...(data ?? [])],
              loading: false,
            });
          }
        });
    });
  };

  onClearFilter = () => {
    this.setState(this.getDefaultState(), () => {
      this.getResults();
    });
  };

  onSearch = () => {
    this.setState({
      ...this.getDefaultResultsState(),
      ...{
        function: this.state.function,
        location: this.state.location,
        filter: {
          function: this.state.function,
          location: this.state.location,
        },
      },
    });
  };

  isSearchDisabled = () => {
    return this.state.function?.trim() == "" && this.state.location?.trim() == "";
  };

  onSelectedClick = (v) => {
    if (this.state.selectedIds.includes(v)) {
      this.setState({
        selectedIds: this.state.selectedIds.filter((id) => {
          return id !== v;
        }),
      });
    } else {
      this.setState({ selectedIds: [...this.state.selectedIds, ...[v]] });
    }
  };

  onRefChange = (node) => {
    if (node) {
      const lazyLoad = (target) => {
        let settings = { rootMargin: "50px" };

        if (this.state.selectedId) {
          settings = {
            root: target.parentNode,
            rootMargin: "200px",
          };
        }

        const io = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && this.state.loading === false && this.state.results?.length > 0 && this.state.results.length < this.state.totalRows) {
              this.setState({ page: this.state.page + 1 });
            }
          });
        }, settings);

        io.observe(target);
      };
      lazyLoad(node);
    }
  };

  render() {
    let loading = (
      <NanoFlex className="elevusLoading">
        <img src={LoaderElevus} alt="loading..." />
      </NanoFlex>
    );
    let scrollTrigger = <NanoFlex ref={this.onRefChange} className="scrollTrigger"></NanoFlex>;
    return (
      <StyledRecruitment isCandidate={this.state.isCandidate} isContactUsActive={this.state.selectedIds?.length > 0}>
        <NanoFlex className="recruitmentHeaderWrapper">
          <NanoFlex className="recruitmentHeader">
            <NanoFlex className="headerInfo" flexDirection="column">
              <NanoFlex className="headerTitle" justifyContent="flex-start">
                <Type.h2>
                  <b>{this.translation.services.recruitment.header.title}</b>
                </Type.h2>
              </NanoFlex>

              <NanoFlex className="headerArticle" justifyContent="flex-start">
                <Type.h5>{this.translation.services.recruitment.header.article}</Type.h5>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="headerImg">
              <img src={RecruitmentHeader} alt="image" />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>

        {/* RECRUITMENT LIST AND SEARCH BLOCK */}
        <NanoFlex className="recruitmentSearchBlock" flexDirection="column">
          {/* RECRUITMENT TOGGLE*/}
          <NanoFlex className="toggleRecruitmentBlock">
            <RecruitmentToggle
              isCandidate={this.state.isCandidate}
              onChange={(c) => {
                if (this.state.isCandidate !== c) {
                  this.setState({ isCandidate: c, ...this.getDefaultResultsState() });
                }
              }}
            />
          </NanoFlex>

          {/* RECRUITMENT SEARCH*/}
          <NanoFlex className="searchBoxesRecruitment" flexWrap="wrap">
            <NanoFlex className="textAreaWrapper">
              <Input title={this.translation.services.recruitment.searchBlock.form.function} placeholder={this.translation.services.recruitment.searchBlock.form.function} defaultValue={this.state.function} onChangeAction={(val) => this.setState({ function: val })} />
            </NanoFlex>
            <NanoFlex className="textAreaWrapper">
              <Input title={this.translation.services.recruitment.searchBlock.form.location} placeholder={this.translation.services.recruitment.searchBlock.form.location} defaultValue={this.state.location} onChangeAction={(val) => this.setState({ location: val })} />
            </NanoFlex>
            <NanoFlex className={`textAreaWrapper ${this.isSearchDisabled() ? "disabled" : ""}`}>
              <SimpleButton label={this.translation.global.search} clickAction={this.onSearch} />
            </NanoFlex>
          </NanoFlex>

          {/* NUMERO DE OPORTUNIDADES */}
          <NanoFlex ref={this._totalRowsRef} className="opportunitiesNumberWrapper" justifyContent="flex-start">
            <NanoFlex justifyContent="flex-start" className="offerNumberWrapper">
              <Type.h3>
                <b className="coloredText">{this.state.totalRows}</b>
              </Type.h3>
              <Type.h3>
                <b>{this.translation.global.offers}</b>
              </Type.h3>
            </NanoFlex>
            {/* DIV BELLOW SHOULD ONLY APPEAR AFTER FILTERED SEARCH */}
            {this.state.filter && (
              <NanoFlex justifyContent="flex-end" className="cleanSearchFilter" onClick={() => this.setState(this.getDefaultResultsState())}>
                <Type.p>{this.translation.global.clearSearch}</Type.p>
              </NanoFlex>
            )}
          </NanoFlex>

          {/* RECRUITMENT TOP OPORTUNITYS LIST*/}
          {!this.state.selectedId && (
            <NanoFlex className="recruitmentListFlex" flexWrap="wrap" justifyContent="flex-start">
              {this.state.results?.map((d) => {
                if (this.state.isCandidate) {
                  return <CandidateItem key={d.id} information={d} itemClick={() => this.setState({ selectedId: d.id, scrollToSelected: true })} />;
                } else {
                  return <RecruitmentItem key={d.id} information={d} itemClick={() => this.setState({ selectedId: d.id, scrollToSelected: true })} />;
                }
              })}
              {scrollTrigger}
              {this.state.loading && loading}
            </NanoFlex>
          )}

          {this.state.selectedId && (
            <NanoFlex className="recruitmentDetail" alignItems="flex-start">
              <NanoFlex className="listFullWrapper" flexDirection="column">
                <NanoFlex className="listFullScroll" flexDirection="column" justifyContent="flex-start">
                  {this.state.results?.map((d) => {
                    let isOpen = d.id === this.state.selectedId;
                    if (this.state.isCandidate) {
                      return <CandidateItem key={d.id} information={d} isOpen={isOpen} itemClick={() => this.setState({ selectedId: isOpen ? null : d.id })} scrollToSelected={isOpen && this.state.scrollToSelected} />;
                    } else {
                      return (
                        <RecruitmentItem
                          key={d.id}
                          information={d}
                          isOpen={isOpen}
                          itemClick={() => this.setState({ selectedId: isOpen ? null : d.id })}
                          hasSelected
                          isSelected={this.state.selectedIds.includes(d.id)}
                          selectedClick={() => this.onSelectedClick(d.id)}
                          scrollToSelected={isOpen && this.state.scrollToSelected}
                        />
                      );
                    }
                  })}
                  {scrollTrigger}
                  {this.state.loading && loading}
                </NanoFlex>
                {/* BUTTON BELLOW ONLY SHOWS FOR COMPANY OPORTUNITIES VIEW */}
                {!this.state.isCandidate && (
                  <NanoFlex className="contactSelectedWrapper" justifyContent="center" alignItems="center">
                    <SimpleButton label={this.translation.global.contactAll} className="contactSelectedBtn" clickAction={() => this.setState({ openModalContact: true })} />
                    <Modal show={this.state.openModalContact} content={<ModalContactCompany selectedCandidates={this.state.selectedIds} cancelAction={() => this.setState({ openModalContact: false })} nextAction={() => this.setState({ selectedIds: [], openModalContact: false })} />} />
                  </NanoFlex>
                )}
              </NanoFlex>
              {/* OPEN OPPORTUNITY DETAIL*/}
              {this.state.isCandidate && <CandidateItemDetail item={this.state.results?.find((r) => r.id === this.state.selectedId)} />}
              {!this.state.isCandidate && <RecruitmentItemDetail item={this.state.results?.find((r) => r.id === this.state.selectedId)} />}
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledRecruitment>
    );
  }
}
