import React from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex.js";

const StyledMainButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  margin: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  .buttonWrapper {
    /* min-width: 264px; */
    min-width: 220px;
    height: 42px;
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    text-transform: uppercase;
    padding: 8px 24px;
    border-radius: 50px;
    border: none;
    border: 0;
    background-color: ${(props) => props.theme.color.main.blue};
    text-transform: uppercase;
    color: ${(props) => props.theme.color.main.white};
    font-size: 16px;
    font-weight: bold;

    background-size: 300% 100%;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(to right, #00275b, #326ab4, #d73f00, #ffbd42);

    &:hover {
      background-position: 100% 0;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
    &:focus {
      outline: 0;
    }
  }
  .disabled {
    background-image: unset;
    background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
    border: 2px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
    pointer-events: none;
    color: ${(props) => props.theme.color.main.font};
  }
`;

const MainButton = (props) => {
  return (
    <StyledMainButton className="button">
      <button className={`buttonWrapper ${props.disabled ? "disabled" : ""}`} type={props.type} onClick={() => props.clickAction(props.id)}>
        {props.label}
      </button>
    </StyledMainButton>
  );
};

// Set default props
MainButton.defaultProps = {
  id: 0,
  label: "",
  type: "submit",
  clickAction: () => null,
};

export default MainButton;

/*
  Doc
  import Cta from './Buttons/MainButton.js';
  
  <MainButton label="Contacte-nos"/>
  <MainButton pink label="Contacte-nos"/>
  <MainButton blue label="Contacte-nos"/>
  */
