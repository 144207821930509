import { BaseService } from "./BaseService";
import { getUrlParams } from "../components/Global/tools.js";

export class RecruitmentRequestService extends BaseService {
    getRecruitmentRequestWebsiteSharingJobs(filter) {
        return this.request(`RecruitmentRequest/GetRecruitmentRequestWebsiteSharingJobs?${getUrlParams(filter)}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "GET"
        });
    }
}

const recruitmentRequestService = new RecruitmentRequestService();

export default recruitmentRequestService;
