import Type from "./../../../Global/Typography.js";
import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import moment from "moment";
import Settings from "./../../../Global/Settings.json";

import { ReactComponent as Location } from "./../../../Global/icons/location.svg";
import { ReactComponent as Job } from "./../../../Global/icons/job.svg";
import { ReactComponent as Share } from "./../../../Global/icons/share.svg";

import SimpleButton from "../../../Buttons/SimpleButton.js";

const StyledCandidateItemDetail = styled(NanoFlex)`
  width: 71%;
  padding: 20px 35px;
  justify-content: flex-start;

  .openDetailContent {
    background-color: ${(props) => props.theme.color.main.white};
    border-radius: 10px;
    overflow: hidden;
    padding: 25px 0 0 0;
    height: 100%;
    width: 100%;
    gap: 15px;

    .detailContentHeader {
      height: fit-content;

      .detailContentTitleWrapper {
        background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
        padding: 25px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        gap: 15px;
        height: fit-content;
        width: 100%;

        .detailTitleHeader {
          h4 {
            font-size: 26px;
            line-height: 26px;
          }
        }

        .locationTitle {
          gap: 10px;
          height: fit-content;
          margin-right: -5px;

          svg {
            height: 16px;
            width: auto;

            path {
              fill: ${(props) => props.theme.color.colorsPalette.lightOrange};
            }
          }

          p {
            line-height: 16px;
          }
        }
        .workTypeTitle {
          gap: 10px;
          height: fit-content;

          svg {
            height: 14px;
            width: auto;

            path {
              fill: ${(props) => props.theme.color.colorsPalette.lightOrange};
            }
          }

          p {
            line-height: 16px;
          }
        }
      }

      .iconShareWrapper {
        width: 30%;
        padding: 0 25px;

        .iconShare {
          background-color: ${(props) => props.theme.color.colorsPalette.lightOrange};
          border-radius: 100%;
          width: 35px;
          height: 35px;
          cursor: pointer;
          transition: all 0.4s ease-in-out;

          svg {
            width: 14px;
            height: auto;

            path {
              fill: ${(props) => props.theme.color.main.white};
            }
          }

          &:hover {
            background-color: ${(props) => props.theme.color.colorsPalette.opacityOrange};
          }
        }
      }
    }

    .detailFullContent {
      padding: 0 25px;
      overflow-y: auto;
      scroll-behavior: smooth;
      max-height: 46vh;
      min-height: 46vh;
      gap: 20px;

      .detailsWrapper {
        p {
          min-height: 20px;
        }
      }
    }

    .bottomInfo {
      padding: 5px 25px;
      height: auto;
      .btnWrapper {
        .button {
          .buttonWrapper {
            background-color: ${(props) => props.theme.color.colorsPalette.lightOrange};
          }
        }
      }
    }

    .applyBtnWrapper {
      background-color: ${(props) => props.theme.color.main.white};
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 0 25px 15px 25px;
      height: fit-content;

      .releaseDateWrapper {
        p {
          font-size: 12px;
          color: ${(props) => props.theme.color.main.font};
        }
      }

      .button {
        .buttonWrapper {
          background-color: ${(props) => props.theme.color.main.blue};
          min-width: 252px;
        }
      }
    }
  }
`;

export default class CandidateItemDetail extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledCandidateItemDetail className="detailContentFullWrapper" flexDirection="column">
        <NanoFlex className="openDetailContent" alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
          <NanoFlex className="detailContentHeader" alignItems="flex-start" justifyContent="space-between">
            <NanoFlex className="detailContentTitleWrapper" flexDirection="column">
              <NanoFlex className="detailTitleHeader" justifyContent="flex-start">
                <Type.h4>{this.props.item.jobTitle}</Type.h4>
              </NanoFlex>
              <NanoFlex className="locationTitle" justifyContent="flex-start">
                <Location />
                <Type.p>{this.props.item.location ?? "-"}</Type.p>
              </NanoFlex>
              {/* <NanoFlex className="workTypeTitle" justifyContent="flex-start">
                <Job />
                <Type.p>{this.props.item.workType ?? "-"}</Type.p>
              </NanoFlex> */}
            </NanoFlex>
            <NanoFlex className="iconShareWrapper" justifyContent="flex-end" alignItems="flex-start">
              <a href={"/request/" + this.props.item.id} target="_blank">
                <NanoFlex className="iconShare" alignItems="center">
                  <Share />
                </NanoFlex>
              </a>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="detailFullContent" alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
            {this.props.item.functionDescription?.trim() !== "" && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                {this.props.item.functionDescription.split("\n").map((s, i) => (
                  <Type.p key={i}>{s}</Type.p>
                ))}
              </NanoFlex>
            )}
            {this.props.item.requestedProfiles?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.p>
                  <b>{this.translation.global.profile}</b>
                </Type.p>
                {this.props.item.requestedProfiles.map((r) => (
                  <Type.p key={r.id}>{r.name}</Type.p>
                ))}
              </NanoFlex>
            )}
            {this.props.item.requestedAreas?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.p>
                  <b>{this.translation.global.area}</b>
                </Type.p>
                {this.props.item.requestedAreas.map((r) => (
                  <Type.p key={r.id}>{r.name}</Type.p>
                ))}
              </NanoFlex>
            )}
            {this.props.item.requestedSkills?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.p>
                  <b>{this.translation.global.specialization}</b>
                </Type.p>
                {this.props.item.requestedSkills.map((r) => (
                  <Type.p key={r.id}>{r.name}</Type.p>
                ))}
              </NanoFlex>
            )}
            {this.props.item.requestedLanguages?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.p>
                  <b>{this.translation.global.languages}</b>
                </Type.p>
                {this.props.item.requestedLanguages.map((r) => (
                  <Type.p key={r.language.id}>
                    {r.language.name} {r.level.name}
                  </Type.p>
                ))}
              </NanoFlex>
            )}
            {this.props.item.observations?.trim() !== "" && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                {this.props.item.observations.split("\n").map((s, i) => (
                  <Type.p key={i}>{s}</Type.p>
                ))}
              </NanoFlex>
            )}
          </NanoFlex>
          <NanoFlex className="bottomInfo" justifyContent="space-between">
            <NanoFlex className="dateWrapper" justifyContent="flex-start">
              <Type.h6>
                {this.translation.global.publishedin} {moment(this.props.item.publishedDate).format(Settings.moment.displayDate)}
              </Type.h6>
            </NanoFlex>
            <NanoFlex
              className="btnWrapper"
              justifyContent="flex-end"
              onClick={() => {
                window.open(`${window.env.FrontEndUrl}/Candidatura?recruitmentRequestId=${this.props.item.id}`, "_blank", "noopener,noreferrer");
              }}>
              <SimpleButton label={this.translation.global.application} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledCandidateItemDetail>
    );
  }
}
