import React from "react";
import styled from "styled-components";
import BaseComponent from "./../BaseComponent.js";
import NanoFlex from "../Global/NanoFlex.js";
import Input from "../Forms/Input.js";

const StyledRecruitmentToggle = styled(NanoFlex)`
  height: auto;
  width: auto;
  .recruitmentTypeInput {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 200px;
    background-color: ${(props) => props.theme.color.main.white};
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 5px;
    width: 500px;
    max-width: 500px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

    label.radioCompany.active {
      background-color: ${(props) => props.theme.color.main.blue};

      .nameType {
        color: ${(props) => props.theme.color.main.white};
      }
    }

    label.radioCandidate.active {
      background-color: ${(props) => props.theme.color.colorsPalette.lightOrange};

      .nameType {
        color: ${(props) => props.theme.color.main.white};
      }
    }

    .radioCompany,
    .radioCandidate {
      flex: 1 1 auto;
      text-align: center;
      border-radius: 20px;

      .nameType {
        display: flex;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border-radius: 200px;
        width: 100%;
        max-width: 100%;
        border: none;
        padding: 0.5rem 0;
        color: ${(props) => props.theme.color.main.font};
        transition: all 300ms ease-in-out;
      }
    }
  }

  @media only screen and (max-width: 1074px) {
    .recruitmentTypeInput {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export default class RecruitmentToggle extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledRecruitmentToggle className="recruitmentToggleWrapper">
        <NanoFlex className="recruitmentTypeInput">
          <label className={`radioCandidate ${this.props.isCandidate ? "active" : ""}`} onClick={() => this.props.onChange(true)}>
            {/* <input type="radio" className="inputToggle" name="radio" checked="checked" /> */}
            <span className="nameType">{this.translation.services.recruitment.searchBlock.geral.candidateArea}</span>
          </label>
          <label className={`radioCompany ${this.props.isCandidate ? "" : "active"}`} onClick={() => this.props.onChange(false)}>
            {/* <input type="radio" className="inputToggle" name="radio" /> */}
            <span className="nameType">{this.translation.services.recruitment.searchBlock.geral.companyArea}</span>
          </label>
        </NanoFlex>
      </StyledRecruitmentToggle>
    );
  }
}
