import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import SimpleButton from "../../Buttons/SimpleButton.js";

//Imgs
import HomepageHeaderIMG from "./../../Global/images/homepageHeader.jpg";

// Componets
import { CTAButton } from "../../Buttons/CTAButton.js";

const StyledHeaderHomepage = styled(NanoFlex)`
  .articleWrapper {
    background: ${(props) => props.theme.color.brandingColors.brandOrange};
    padding: 5%;
    height: auto;
    gap: 20px;

    .textWrapper {
      text-align: center;
      h1 {
        b {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
  }
  .imgWrapper {
    height: auto;
    max-height: 656px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .ctaContainer {
    a {
      text-decoration: none;
    }

    .button {
      button {
        background-color: ${(props) => props.theme.color.main.white};
        color: ${(props) => props.theme.color.main.font};
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    position: relative;
    align-items: flex-end;
    .articleWrapper {
      position: absolute;
      background: unset;
      justify-content: flex-end;
      .textWrapper {
        height: auto;
      }
      .ctaContainer {
        height: auto;

        a {
          text-decoration: none;
        }
      }
    }
  }
`;

export default class HeaderHomepage extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledHeaderHomepage className="headerHomepage" justifyContent="stretch" alignItems="stretch" alignContent="stretch">
        <NanoFlex className="articleWrapper" flexDirection={"column"}>
          <NanoFlex className="textWrapper" justifyContent="flex-start">
            <Type.h1>
              <b>{this.translation.homepage.headerTitle}</b>
            </Type.h1>
          </NanoFlex>
          <NanoFlex className="ctaContainer" justifyContent="center">
            <Link to={"#contacts"}>
              <SimpleButton label={this.translation.global.letsTalk} />
            </Link>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="imgWrapper">
          <img src={HomepageHeaderIMG} alt="Homepage Header Image" />
        </NanoFlex>
      </StyledHeaderHomepage>
    );
  }
}
