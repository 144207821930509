import Type from "./../../../Global/Typography.js";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import Settings from "./../../../Global/Settings.json";

// Componets
import NanoFlex from "./../../../Global/NanoFlex.js";

//Imgs
import { ReactComponent as Location } from "./../../../Global/icons/location.svg";
import { ReactComponent as Job } from "./../../../Global/icons/job.svg";
import { ReactComponent as Eye } from "./../../../Global/icons/eye.svg";
import { ReactComponent as IconToSelect } from "./../../../Global/icons/selectionIcon.svg";

const StyledRecruitmentItem = styled(NanoFlex)`
  flex: 1 1 23%;
  height: auto;
  cursor: pointer;
  width: 23%;
  max-width: 23%;
  min-width: 23%;
  -webkit-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
  margin-bottom: 8px;
  border-radius: 10px;

  .asideInfo {
    background-color: ${(props) => props.theme.color.main.white};
    padding: 16px;
    max-width: 85%;

    .infoWrapper {
      height: auto;
      margin-bottom: 16px;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      svg {
        width: 14px;
        height: auto;
        margin-right: 8px;
        path {
          fill: ${(props) => props.theme.color.main.blue};
        }

        p {
          b {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
        margin-top: 8px;
      }
    }
    .iconInfo {
      margin-bottom: 8px;
    }
  }
  .companyAside {
    padding: 16px 0 0 0;
    .infoWrapper {
      padding: 0 16px;
    }
    .selectBtnWrapper {
      padding: 8px 16px;
      cursor: pointer;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey2};
      svg {
        height: 14px;
        width: auto;
        path {
          fill: ${(props) => props.theme.color.main.font};
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .selectedItem {
      background-color: ${(props) => props.theme.color.colorsPalette.opacityBlue};
      svg {
        path {
          fill: ${(props) => props.theme.color.main.white};
        }
      }
    }
  }

  .actionRecruitmentIcons {
    padding: 16px 0 16px 10px;
    gap: 62px;
    width: 15%;

    .selectionCompany {
      width: auto;
      padding: 0 11px 0 0;

      svg {
        width: 16px;
        height: auto;
        path {
          fill: ${(props) => props.theme.color.main.opacityBlue};
        }
      }

      &.selected {
        svg {
          path {
            fill: ${(props) => props.theme.color.colorsPalette.selectedBlue};
          }
        }
      }
    }

    .arrowWrapper {
      width: auto;
      padding: 11px;
      background-color: ${(props) => props.theme.color.main.blue};
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      align-self: flex-end;
      transition: all 0.4s ease-in-out;

      svg {
        width: 20px;
        height: auto;

        path {
          fill: ${(props) => props.theme.color.main.white};
        }
      }

      &.selected {
        background-color: ${(props) => props.theme.color.colorsPalette.selectedBlue};
      }

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    -webkit-box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 6px 15px -9px rgba(0, 0, 0, 0.75);
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1200px) {
    flex: 1 1 30%;
    width: 32%;
    max-width: 32%;
  }

  @media only screen and (max-width: 1074px) {
    flex: 1 1 50%;
    width: 46%;
    max-width: 46%;
  }

  @media only screen and (max-width: 530px) {
    flex: 1 100%;
    width: 100%;
    max-width: 100%;
  }
`;

export default class RecruitmentItem extends BaseComponent {
  constructor(props) {
    super(props);

    this.itemRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.props.scrollToSelected) {
      this.scrollToParent();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.scrollToSelected !== this.props.scrollToSelected && this.props.scrollToSelected) {
      this.scrollToParent();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  scrollToParent = () => {
    if (this.itemRef?.current) {
      setTimeout(() => {
        this.itemRef.current.parentElement.scrollTop += this.itemRef.current.getBoundingClientRect().top - 80;
      }, 500);
    }
  };

  render() {
    return (
      <StyledRecruitmentItem ref={this.itemRef} className="singleItemSmall">
        <NanoFlex className="asideInfo" flexDirection="column" alignItems="flex-start">
          <NanoFlex className="infoWrapper" justifyContent="flex-start">
            <Type.p>
              <b>{this.props.information.id}</b>
            </Type.p>
          </NanoFlex>
          <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
            <Location />
            <Type.p>{this.props.information.cityPreference?.join(", ") ?? "-"}</Type.p>
          </NanoFlex>
          <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
            <Job />
            <Type.p>{this.props.information.professionalExperience ?? "-"}</Type.p>
          </NanoFlex>
          {/* <NanoFlex className="infoWrapper" justifyContent="flex-start">
            <Type.h6>
              {this.translation.global.publishedin} {moment(this.props.information.publishedDate).format(Settings.moment.displayDate)}
            </Type.h6>
          </NanoFlex> */}
        </NanoFlex>

        <NanoFlex className="actionRecruitmentIcons" flexDirection="column" justifyContent="space-between" alignItems="flex-end">
          {/* ICON BELLOW ONLY SHOWS FOR COMPANY OPORTUNITIES VIEW */}
          {this.props.hasSelected && (
            <NanoFlex className={`selectionCompany ${this.props.isSelected ? "selected" : ""}`} onClick={this.props.selectedClick}>
              <IconToSelect />
            </NanoFlex>
          )}

          <NanoFlex className={`arrowWrapper ${this.props.isOpen ? "selected" : ""}`} onClick={this.props.itemClick}>
            <Eye />
          </NanoFlex>
        </NanoFlex>
      </StyledRecruitmentItem>
    );
  }
}
