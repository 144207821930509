import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import CareersHeader01 from "./../../Global/images/careersHeader01.jpg";
import CareersHeader02 from "./../../Global/images/careersHeader02.jpg";
import CareersHeader03 from "./../../Global/images/careersHeader03.jpg";
import CareersHeader04 from "./../../Global/images/careersHeader04.jpg";
import { ReactComponent as CloudTop } from "./../../Global/images/cloudtop.svg";

// Componets
import { CTAButton } from "../../Buttons/CTAButton.js";

const StyledHeaderCareers = styled(NanoFlex)`
  height: auto;
  position: relative;
  margin-bottom: 30px;

  .whiteLine {
    position: relative;
    z-index: 1;
    height: 80px;
    background-color: ${(props) => props.theme.color.main.white};
  }
  .headerContainer {
    height: auto;
    position: relative;
    .asideWrapper {
      height: auto;
      background-color: ${(props) => props.theme.color.main.blue};
      position: relative;
      .headerTitleContainer {
        padding-top: 48px;
        position: relative;
        z-index: 1;
        .textWrapper {
          height: auto;
          max-width: 576px;
          margin-bottom: 32px;
          h1 {
            b {
              color: ${(props) => props.theme.color.main.white};
            }
          }
        }
        .ctaContainer {
          height: auto;
        }
      }
      .imgsContainer {
        position: relative;
        z-index: 1;
        .imgWrapper {
          height: 100%;
          width: auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .leftContainer {
        margin-right: 48px;
        .img1 {
          margin-top: 85px;
          max-width: 360px;
        }
        .img2 {
          margin-top: -80px;
          margin-left: -48px;
          max-width: 344px;
          img {
            object-position: top;
          }
        }
      }

      .rightContainer {
        margin-top: 56px;
        margin-left: -240px;
        .img3 {
          margin-top: -85px;
          max-width: 280px;
          position: relative;
        }
        .img4 {
          margin-top: 80px;
          margin-left: -48px;
          max-width: 344px;
          img {
            object-position: top;
          }
        }
      }
    }
  }

  .backgroundWrapper {
    position: absolute;
    bottom: 0;
    background-color: ${(props) => props.theme.color.main.blue};
    svg {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 0;
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    .headerWrapper {
      .whiteLine {
        display: none;
      }
      .headerContainer {
        .leftAside {
          display: none;
        }
        .asideWrapper {
          .headerTitleContainer {
            .textWrapper {
              max-width: 80%;
              padding: 0 40px;
              margin-bottom: 24px;
            }
            .ctaContainer {
              padding: 0 40px;
            }
          }
          .rightContainer {
            margin-top: 36px;
            justify-content: flex-end;
            margin-left: 0;
            .img3 {
              order: 1;
              img {
                object-position: top;
              }
            }
            .img4 {
              margin-left: 0;
              margin-top: 0;
              margin-right: -48px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 674px) {
    .headerWrapper {
      .headerContainer {
        .asideWrapper {
          justify-content: flex-end;
          background-color: unset;
          min-height: 60vh;
          .headerTitleContainer {
            justify-content: flex-end;
            background: ${(props) => props.theme.color.colorsPalette.opacityBlue};
            padding: 20px 0;
            height: auto;
          }
          .rightContainer {
            position: absolute;
            margin: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            .img3 {
              width: 100%;
              height: 100%;
              max-width: initial;
              margin: 0;
              img {
                object-fit: cover;
              }
            }
            .img4 {
              display: none;
            }
          }
        }
      }
      .backgroundWrapper {
        background-color: unset;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .headerWrapper {
      .headerContainer {
        .asideWrapper {
          .headerTitleContainer {
            .textWrapper {
              max-width: initial;
              margin-bottom: 16px;
              padding: 0 20px;
            }
            .ctaContainer {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
`;

export default class HeaderCareers extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledHeaderCareers className="headerCareers" flexDirection={"column"}>
        <NanoFlex className="headerWrapper" flexDirection={"column"}>
          <NanoFlex className="whiteLine"></NanoFlex>
          <NanoFlex className="headerContainer" justifyContent="stretch" alignItems="stretch" alignContent="stretch">
            <NanoFlex className="asideWrapper leftAside">
              <NanoFlex className="imgsContainer leftContainer">
                <NanoFlex className="imgWrapper img1">
                  <img src={CareersHeader01} alt="Career" />
                </NanoFlex>
                <NanoFlex className="imgWrapper img2">
                  <img src={CareersHeader02} alt="Career" />
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="asideWrapper" flexDirection={"column"} justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="headerTitleContainer" flexDirection={"column"} justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="textWrapper" justifyContent="flex-start">
                  <Type.h1>
                    <b>{this.translation.careers.header.title}</b>
                  </Type.h1>
                </NanoFlex>
                <NanoFlex className="ctaContainer" justifyContent="flex-start">
                  <CTAButton white link={"#contacts"} />
                </NanoFlex>
              </NanoFlex>

              <NanoFlex className="imgsContainer rightContainer" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="imgWrapper img3">
                  <img src={CareersHeader03} alt="Career" />
                </NanoFlex>
                <NanoFlex className="imgWrapper img4">
                  <img src={CareersHeader04} alt="Career" />
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="backgroundWrapper" alignItems="flex-end">
            <CloudTop />
          </NanoFlex>
        </NanoFlex>
      </StyledHeaderCareers>
    );
  }
}
