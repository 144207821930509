import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import { ReactComponent as PoweredMedialog } from "./../../Global/images/poweredByMedialog.svg";
import PortugalCertificationLogo from "./../../Global/images/Portugal_Certification_Logo.png";
import Dgert from "./../../Global/images/dgert.png";
import ProfileTOP5 from "./../../Global/images/PROFILE-TOP5.png";
import MostLoved from "./../../Global/images/MOST-LOVED.png";

const StyledFooter = styled(NanoFlex)`
  height: auto;
  background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
  padding: 32px;
  .footerTop {
    .footerListWrapper {
      margin-bottom: 32px;
      .footerList {
        width: auto;
        margin-right: 40px;
        .titleList {
          height: auto;
          margin-bottom: 8px;
          h6 {
            color: ${(props) => props.theme.color.main.font};
            text-transform: uppercase;
          }
        }
        .footerItemsList {
          .footerItem {
            height: auto;
            margin-bottom: 4px;
            a {
              text-decoration: none;
              p {
                color: ${(props) => props.theme.color.main.font};
              }
              &:hover {
                p {
                  transition: ${(props) => props.theme.transition};
                  text-decoration: underline;
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .certificatesContainer {
      .imgsWrapper {
        width: auto;

        .logoWrapper {
          margin-right: 16px;
          a {
            text-decoration: none;
            img {
              height: 100px;
              width: auto;
              object-fit: contain;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .footerBottom {
    .asideBottom {
      h6 {
        color: ${(props) => props.theme.color.main.font};
      }

      svg {
        height: 23px;
        width: auto;
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 380px) {
    .footerListWrapper {
      flex-direction: column;
      .footerList {
        margin-right: 0px;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .certificatesContainer {
      justify-content: center;
      margin-bottom: 16px;
    }
  }
  @media only screen and (max-width: 950px) {
    .footerTop {
      flex-direction: column;
      .certificatesContainer {
        justify-content: flex-start;
      }
    }
  }
`;

export class Footer extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledFooter className="footer" flexDirection="column">
        <NanoFlex className="footerTop">
          <NanoFlex className="footerListWrapper" justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start">
            {/* LIST TO REPEAT */}
            <NanoFlex className="footerList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="titleList" justifyContent="flex-start">
                <Type.h6>
                  <b>{this.translation.navBar.aboutUs}</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className="footerItemsList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <Link to="/">
                    <Type.p>{this.translation.global.elevus}</Type.p>
                  </Link>
                </NanoFlex>
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <Link to="/services">
                    <Type.p>{this.translation.navBar.services}</Type.p>
                  </Link>
                </NanoFlex>
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <Link to="/careers">
                    <Type.p>{this.translation.navBar.careers}</Type.p>
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* END LIST TO REPEAT */}
            {/* LIST TO REPEAT */}
            <NanoFlex className="footerList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="titleList" justifyContent="flex-start">
                <Type.h6>
                  <b>{this.translation.navBar.services}</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className="footerItemsList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <Link to="/services/">
                    <Type.p>{this.translation.global.recruitment}</Type.p>
                  </Link>
                </NanoFlex>
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <Link to="/services/outsourcing">
                    <Type.p>{this.translation.global.outsourcing}</Type.p>
                  </Link>
                </NanoFlex>
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <Link to="/services/consultingRH">
                    <Type.p>{this.translation.global.consultingRh}</Type.p>
                  </Link>
                </NanoFlex>
                {/*<NanoFlex className="footerItem" justifyContent="flex-start">*/}
                {/*  <Link to="/services/formation">*/}
                {/*    <Type.p>{this.translation.global.formation}</Type.p>*/}
                {/*  </Link>*/}
                {/*</NanoFlex>*/}
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <Link to="/services/assessmentCenter">
                    <Type.p>{this.translation.global.asssessmentCenter}</Type.p>
                  </Link>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* END LIST TO REPEAT */}
            {/* LIST TO REPEAT */}
            <NanoFlex className="footerList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="titleList" justifyContent="flex-start">
                <Type.h6>
                  <b>{this.translation.navBar.contacts}</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className="footerItemsList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <Link to="#contacts">
                    <Type.h5>{this.translation.navBar.contactUs}</Type.h5>
                  </Link>
                </NanoFlex>
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <a href={this.translation.global.facebookLink} rel="noopener noreferrer" target="_blank">
                    <Type.p>Facebook</Type.p>
                  </a>
                </NanoFlex>
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <a href={this.translation.global.instagramLink} rel="noopener noreferrer" target="_blank">
                    <Type.p>Instagram</Type.p>
                  </a>
                </NanoFlex>
                <NanoFlex className="footerItem" justifyContent="flex-start">
                  <a href="https://www.linkedin.com/company/elevus-lisboa/" rel="noopener noreferrer" target="_blank">
                    <Type.p>LinkedIn</Type.p>
                  </a>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* END LIST TO REPEAT */}
          </NanoFlex>
          <NanoFlex className="certificatesContainer" justifyContent="flex-end">
            <NanoFlex className="imgsWrapper" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="logoWrapper">
                <a href="#" rel="noopener noreferrer" target="_blank">
                  <img className="dgertLogo" src={Dgert} alt="Dgert" />
                </a>
              </NanoFlex>
              <NanoFlex className="logoWrapper">
                <a href="https://scoring.pt/empresas/elevus/" rel="noopener noreferrer" target="_blank">
                  <img className="profileTopLogo" src={ProfileTOP5} alt="Profile TOP5" />
                </a>
              </NanoFlex>
              <NanoFlex className="logoWrapper">
                <a href="#" rel="noopener noreferrer" target="_blank">
                  <img className="mostLovedLogo" src={MostLoved} alt="mostLoved" />
                </a>
              </NanoFlex>
              <NanoFlex className="logoWrapper">
                <a href="https://www.greatplacetowork.pt/empresas-certificadas/elevus" rel="noopener noreferrer" target="_blank">
                  <img className="certificationLogo" src={PortugalCertificationLogo} alt="Portugal Certification" />
                </a>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="footerBottom" justifyContent="space-between" alignItems="flex-end">
          <NanoFlex className="asideBottom" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Type.h6>© 2024 ELEVUS</Type.h6>
          </NanoFlex>
          <NanoFlex className="asideBottom" justifyContent="flex-end" alignItems="flex-end">
            <a href="https://www.medialog.pt/" rel="noopener noreferrer" target="_blank">
              <PoweredMedialog />
            </a>
          </NanoFlex>
        </NanoFlex>
      </StyledFooter>
    );
  }
}
