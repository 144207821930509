import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

//IMGS
import BlockImage from "./../../../Global/images/blockIMG.jpg";

const StyledListBlock = styled(NanoFlex)`
  height: auto;
  margin-bottom: 129px;
  .listBlockWrapper {
    padding: 40px;
    width: calc(100% - 80px);

    .titleBlockWrapper {
      width: 57%;
      margin-right: -263px;
      z-index: 1;
      .titleBlock {
        width: 428px;
        padding: 0;
        h2 {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.main.blue};
          font-weight: bold;
          line-height: 64px;
          em {
            color: ${(props) => props.theme.color.colorsPalette.lightOrange};
            font-size: 52px;
          }
        }
      }
    }

    .listContainer {
      background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
      padding: 48px 222px 48px 210px;
      .listItem {
        margin-bottom: 24px;
        h5 {
          text-transform: uppercase;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .imageBlockContainer {
      height: 380px;
      max-height: 380px;
      object-fit: cover;
      overflow: hidden;
      width: auto;
      margin-left: -320px;
      margin-bottom: -221px;

      img {
        height: 380px;
        max-height: 380px;
        object-fit: cover;
        overflow: hidden;
        width: auto;
      }

      @media only screen and (max-width: 1074px) {
        display: none;
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 880px) {
    .listBlockWrapper {
      flex-direction: column;
      background: linear-gradient(180deg, #ffffff 15%, #e7f3fe 15%);
      .titleBlockWrapper {
        margin-right: 0;
        width: 100%;
        justify-content: center;
        .titleBlock {
          min-width: 100%;
          padding: 0 32px;
        }
      }
      .listContainer {
        padding: 32px;
        justify-content: center;
        background-color: unset;
        .listItem {
          width: auto;
        }
      }
    }
  }
  @media only screen and (max-width: 460px) {
    .listBlockWrapper {
      .titleBlockWrapper {
        .titleBlock {
          padding: 0 16px;
          h2 {
            font-size: 28px;
            line-height: 36px;
            em {
              font-size: 36px;
            }
          }
        }
      }
      .listContainer {
        padding: 24px 16px;
        .listItem {
          margin-bottom: 16px;
          h5 {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
`;

export default class ListBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledListBlock className="listBlock">
        <NanoFlex className="listBlockWrapper">
          <NanoFlex className="titleBlockWrapper" justifyContent="flex-end">
            <NanoFlex className="titleBlock">
              <Type.h2 dangerouslySetInnerHTML={{ __html: this.translation.services.consultingRH.listBlock.title }}></Type.h2>
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="listContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-start" alignItems="flex-start">
              <Type.h5>{this.translation.services.consultingRH.listBlock.list.item1}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-start" alignItems="flex-start">
              <Type.h5>{this.translation.services.consultingRH.listBlock.list.item2}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-start" alignItems="flex-start">
              <Type.h5>{this.translation.services.consultingRH.listBlock.list.item3}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-start" alignItems="flex-start">
              <Type.h5>{this.translation.services.consultingRH.listBlock.list.item4}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-start" alignItems="flex-start">
              <Type.h5>{this.translation.services.consultingRH.listBlock.list.item5}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-start" alignItems="flex-start">
              <Type.h5>{this.translation.services.consultingRH.listBlock.list.item6}</Type.h5>
            </NanoFlex>
            {/* ITEM */}
            <NanoFlex className="listItem" justifyContent="flex-start" alignItems="flex-start">
              <Type.h5>{this.translation.services.consultingRH.listBlock.list.item7}</Type.h5>
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="imageBlockContainer">
            <img src={BlockImage} alt="image" />
          </NanoFlex>
        </NanoFlex>
      </StyledListBlock>
    );
  }
}
