import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";

// Componets
import HeaderCareers from "./HeaderCareers.js";
import BenefitsCareers from "./BenefitsCareers.js";
import ListBlock from "./ListBlock.js";

const StyledCareers = styled(NanoFlex)`
  height: auto;
`;

export default class Careers extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledCareers flexDirection={"column"}>
        <HeaderCareers />
        <BenefitsCareers />
        <ListBlock />
      </StyledCareers>
    );
  }
}
