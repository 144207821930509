import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";

//Imgs
import SelectBtnBlock01 from "./../../Global/images/selectBtnBlock01.jpg";

// Componets
import HeaderHomepage from "./HeaderHomepage.js";
import AboutBlock from "./AboutBlock.js";
import SelectBtnsBlock from "../../Blocks/SelectBtnsBlock.js";
import OfficesBlock from "./OfficesBlock.js";

const StyledHomepage = styled(NanoFlex)`
  height: auto;
  .selectBtnBlock {
    padding: 0 10vw;
    .imgWrapper {
      width: 15vw;
      min-width: 325px;
    }
    .articlesBlock {
      .articlesBlockWrapper {
        .btnsContainer {
          .button {
            min-width: 230px;
          }
        }
      }
    }
  }

  .contactBlock {
    .contactFormWrapper {
      background-color: #f3b48b;
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1692px) {
    .selectBtnBlock {
      .articlesBlock {
        .articlesBlockWrapper {
          .btnsContainer {
            padding: 0px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1536px) {
    .selectBtnBlock {
      padding: 0 5vw;
    }
  }
  @media only screen and (max-width: 1290px) {
    .selectBtnBlock {
      padding: 0;
    }
  }
`;

export default class Homepage extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledHomepage flexDirection={"column"}>
        <HeaderHomepage />
        <AboutBlock />
        <SelectBtnsBlock src={SelectBtnBlock01} options={this.translation.homepage.selectBlock.options} />
        <OfficesBlock />
      </StyledHomepage>
    );
  }
}
