export const validateEmailFormat = function (val) {
  var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(val.toLowerCase());
};

export const getUrlParams = function (urlParams) {
  let params = new URLSearchParams(urlParams);
  let keysForDel = [];
  params.forEach((v, k) => {
    if (v == "") keysForDel.push(k);
  });
  keysForDel.forEach((k) => {
    params.delete(k);
  });
  return params.toString();
};

export const elementMatches = (el, selector) => {
  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
};

export const elementMatchesAny = (el, selectorArray) => {
  for (let idx = 0; idx < selectorArray.length; idx++) {
    let v = selectorArray[idx];
    if (elementMatches(el, v) || el.parentElement.closest(v)) {
      return true;
    }
  }
  return false;
};

export const decodeHtml = (content) => {
  let e = document.createElement("div");
  e.innerHTML = content;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

export const duplicateArray = (arr, length) => {
  if (arr && arr.length) {
    let newArr = [...arr];
    if (arr.length) {
      while (newArr.length < length) newArr = [...newArr, ...arr];
    }
    return newArr;
  }
  return null;
};
