import Type from "./../../../Global/Typography.js";
import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

import { ReactComponent as Location } from "./../../../Global/icons/location.svg";
import { ReactComponent as Job } from "./../../../Global/icons/job.svg";
import { ReactComponent as Share } from "./../../../Global/icons/share.svg";

const StyledRecruitmentItemDetail = styled(NanoFlex)`
  width: 71%;
  padding: 20px 35px;
  justify-content: flex-start;

  .openDetailContent {
    background-color: ${(props) => props.theme.color.main.white};
    border-radius: 10px;
    overflow: hidden;
    padding: 25px 0 0 0;
    height: 100%;
    width: 100%;
    gap: 15px;

    .detailContentHeader {
      height: fit-content;

      .detailContentTitleWrapper {
        background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
        padding: 25px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        gap: 15px;
        height: fit-content;
        width: 100%;

        .detailTitleHeader {
          h4 {
            font-size: 26px;
            line-height: 26px;
          }
        }

        .locationTitle {
          gap: 10px;
          height: fit-content;
          margin-right: -5px;

          svg {
            height: 16px;
            width: auto;

            path {
              fill: ${(props) => (props.isCandidate ? props.theme.color.colorsPalette.lightOrange : props.theme.color.main.blue)};
            }
          }

          p {
            line-height: 16px;
          }
        }
        .expTitle {
          gap: 10px;
          height: fit-content;

          svg {
            height: 14px;
            width: auto;

            path {
              fill: ${(props) => (props.isCandidate ? props.theme.color.colorsPalette.lightOrange : props.theme.color.main.blue)};
            }
          }

          p {
            line-height: 16px;
          }
        }
      }

      .iconShareWrapper {
        width: 30%;
        padding: 0 25px;

        .iconShare {
          background-color: ${(props) => (props.isCandidate ? props.theme.color.colorsPalette.lightOrange : props.theme.color.main.blue)};
          border-radius: 100%;
          width: 35px;
          height: 35px;
          cursor: pointer;
          transition: all 0.4s ease-in-out;

          svg {
            width: 14px;
            height: auto;

            path {
              fill: ${(props) => props.theme.color.main.white};
            }
          }

          &:hover {
            background-color: ${(props) => (props.isCandidate ? props.theme.color.colorsPalette.opacityOrange : props.theme.color.colorsPalette.opacityBlue)};
          }
        }
      }
    }

    .detailFullContent {
      padding: 0 25px;
      overflow-y: auto;
      scroll-behavior: smooth;
      max-height: 46vh;
      min-height: 46vh;
      gap: 20px;

      .detailsWrapper {
        p {
          min-height: 20px;
        }
      }
    }

    .applyBtnWrapper {
      background-color: ${(props) => props.theme.color.main.white};
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 0 25px 15px 25px;
      height: fit-content;

      .releaseDateWrapper {
        p {
          font-size: 12px;
          color: ${(props) => props.theme.color.main.font};
        }
      }

      .button {
        .buttonWrapper {
          background-color: ${(props) => (props.isCandidate ? props.theme.color.main.blue : props.theme.color.colorsPalette.lightOrange)};
          min-width: 252px;
        }
      }
    }
  }
`;

export default class RecruitmentItemDetail extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledRecruitmentItemDetail className="detailContentFullWrapper" flexDirection="column">
        <NanoFlex className="openDetailContent" alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
          <NanoFlex className="detailContentHeader" alignItems="flex-start" justifyContent="space-between">
            <NanoFlex className="detailContentTitleWrapper" flexDirection="column">
              <NanoFlex className="detailTitleHeader" justifyContent="flex-start">
                <Type.h4>{this.props.item.id}</Type.h4>
              </NanoFlex>
              <NanoFlex className="locationTitle" justifyContent="flex-start">
                <Location />
                <Type.p>{this.props.item.cityPreference?.join(", ") ?? "-"}</Type.p>
              </NanoFlex>
              <NanoFlex className="expTitle" justifyContent="flex-start">
                <Job />
                <Type.p>{this.props.item.professionalExperience ?? "-"}</Type.p>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="iconShareWrapper" justifyContent="flex-end" alignItems="flex-start">
              <a href={"/candidate/" + this.props.item.id} target="_blank">
                <NanoFlex className="iconShare" alignItems="center">
                  <Share />
                </NanoFlex>
              </a>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="detailFullContent" alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
            {this.props.item.externalObservations && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                {this.props.item.externalObservations.split("\n").map((s) => (
                  <Type.p>{s}</Type.p>
                ))}
              </NanoFlex>
            )}
            {/* {this.props.item.professionalExperience && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>{this.translation.global.experience}</b>
                </Type.h6>
                {this.props.item.professionalExperience && <Type.p>{this.props.item.professionalExperience}</Type.p>}
              </NanoFlex>
            )} */}
            {this.props.item.experience && this.props.item.experience?.period > 0 && this.props.item?.experience?.periodType?.name && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>{this.translation.global.professionalExperienceTime}</b>
                </Type.h6>
                <Type.p>
                  {this.props.item.experience.period} {this.props.item.experience.periodType.name}
                </Type.p>
              </NanoFlex>
            )}
            {this.props.item.salaryRangePreference && (this.props.item.salaryRangePreference.min > 0 || this.props.item.salaryRangePreference.max > 0) && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>{this.translation.global.salary}</b>
                </Type.h6>
                <Type.p>{`${this.props.item.salaryRangePreference.min} - ${this.props.item.salaryRangePreference.max} ${this.props.item.isLiquidSalary ? "Líquidos" : "Brutos"}`}</Type.p>
              </NanoFlex>
            )}
            {this.props.item.habilitation?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>{this.translation.global.qualification}</b>
                </Type.h6>
                {this.props.item.habilitation.courseName && <Type.p>{this.props.item.habilitation.courseName}</Type.p>}
                {this.props.item.habilitation.degree?.name && <Type.p>{this.props.item.habilitation.degree.name}</Type.p>}
              </NanoFlex>
            )}
            {this.props.item.profiles?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>{this.translation.global.profile}</b>
                </Type.h6>
                {this.props.item.profiles.map((r) => (
                  <Type.p key={r}>{r}</Type.p>
                ))}
              </NanoFlex>
            )}
            {this.props.item.areas?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>{this.translation.global.area}</b>
                </Type.h6>
                {this.props.item.areas.map((r) => (
                  <Type.p key={r}>{r}</Type.p>
                ))}
              </NanoFlex>
            )}
            {this.props.item.skills?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>{this.translation.global.specialization}</b>
                </Type.h6>
                {this.props.item.skills.map((r) => (
                  <Type.p key={r}>{r}</Type.p>
                ))}
              </NanoFlex>
            )}
            {this.props.item.languages?.length > 0 && (
              <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>{this.translation.global.languages}</b>
                </Type.h6>
                {this.props.item.languages.map((r) => (
                  <Type.p key={`${r.language.name}_${r.level.name}`}>
                    {r.language.name} {r.level.name}
                  </Type.p>
                ))}
              </NanoFlex>
            )}
          </NanoFlex>
        </NanoFlex>
      </StyledRecruitmentItemDetail>
    );
  }
}
