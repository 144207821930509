import React from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex.js";
import Type from "../Global/Typography.js";

const StyledSelectBtn = styled(NanoFlex)`
  height: auto;
  width: auto;
  min-width: 294px;
  margin: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  .buttonWrapper {
    //height: 42px;
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    pointer-events: ${(props) => (props.selected ? "none" : "auto")};
    padding: 16px 24px;
    border-radius: 50px;
    border: 1px solid ${(props) => (props.selected ? props.theme.color.colorsPalette.lightOrange : props.theme.color.colorsPalette.opacityOrange)};
    background-color: ${(props) => (props.selected ? props.theme.color.colorsPalette.lightOrange : props.theme.color.colorsPalette.opacityOrange)};
    text-transform: uppercase;

    p {
      color: ${(props) => props.theme.color.main.white};
      font-weight: bold;
      line-height: 0;
      margin-top: 2px;
    }
    /* &:hover {
      background-color: #ffbd4282;
    } */
    &:focus {
      outline: 0;
    }
  }
`;

const SelectBtn = (props) => {
  return (
    <StyledSelectBtn className="button" selected={props.selected}>
      {/* <button className="buttonWrapper" type={props.type} onClick={() => props.clickAction(props.id)}>
        {props.label}
      </button> */}
      <NanoFlex className="buttonWrapper" type={props.type} onClick={() => props.clickAction(props.id)}>
        <Type.p>{props.label}</Type.p>
      </NanoFlex>
    </StyledSelectBtn>
  );
};

// Set default props
SelectBtn.defaultProps = {
  id: 0,
  label: "",
  type: "submit",
  clickAction: () => null,
};

export default SelectBtn;
