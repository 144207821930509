import React from "react";
import styled from "styled-components";
import moment from "moment";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import Settings from "./../../../Global/Settings.json";
import { elementMatchesAny } from "../../../Global/tools.js";

// Componets
import SimpleButton from "../../../Buttons/SimpleButton.js";
import Modal from "../../../Modals/Modal.js";
import { ModalContactCompany } from "../../../Modals/ModalContactCompany.js";

//Services
import recruitmentRequestService from "../../../../services/RecruitmentRequestService.js";
import candidatesService from "../../../../services/CandidatesService.js";

// Imgs
import { ReactComponent as Bell } from "./../../../Global/icons/bell.svg";
import { ReactComponent as Location } from "./../../../Global/icons/location.svg";
import { ReactComponent as Job } from "./../../../Global/icons/job.svg";
import { ReactComponent as ArrowRight } from "./../../../Global/icons/arrowRight.svg";
import { ReactComponent as Check } from "./../../../Global/icons/check.svg";

const StyledCandidateDetail = styled(NanoFlex)`
  height: auto;
  padding: 32px;
  background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
  .resultDetails {
    background-color: ${(props) => props.theme.color.main.white};
    -webkit-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 4px 13px -9px rgba(0, 0, 0, 0.75);
    .topInfo {
      .titleWrapper {
        padding: 40px 40px 0 40px;
        margin-bottom: 16px;
        height: auto;
        p {
          text-transform: uppercase;
        }
      }
      .labelResult {
        background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
        width: auto;
        padding: 16px 40px;
        height: auto;
        .infoWrapper {
          margin-bottom: 8px;
          svg {
            width: 14px;
            height: auto;
            margin-right: 8px;
            path {
              fill: ${(props) => (!props.company ? props.theme.color.colorsPalette.blue : props.theme.color.colorsPalette.lightOrange)};
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .detailsResult {
        padding: 0px 40px;
        /* max-height: calc(75vh - 326px); */
        overflow: auto;
        margin-top: 16px;
        .detailsWrapper {
          margin-bottom: 24px;
          height: auto;
          p {
            min-height: 20px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .bottomInfo {
      height: auto;
      padding: 40px;
      .btnWrapper {
        .button {
          .buttonWrapper {
            background-color: ${(props) => (!props.company ? props.theme.color.colorsPalette.blue : props.theme.color.colorsPalette.lightOrange)};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .resultDetails {
      .topInfo {
        .titleWrapper {
          padding: 16px 16px 0 16px;
        }
        .labelResult {
          padding: 16px;
        }
        .detailsResult {
          padding: 0 16px;
          max-height: 344px;
          min-height: 288px;
          .detailsWrapper {
            margin-bottom: 8px;
          }
        }
      }
      .bottomInfo {
        padding: 16px;
        flex-direction: column;
        .dateWrapper {
          justify-content: center;
        }
        .btnWrapper {
          justify-content: center;
          .button {
            .buttonWrapper {
              min-width: 200px;
            }
          }
        }
      }
    }
  }
`;

export default class CandidateDetail extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      detail: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getDetail();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getDetail = () => {
    candidatesService
      .getWebsiteCandidates({
        page: 1,
        pageSize: 1,
        candidateId: this.props.render.match.params.id,
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data && data.length > 0) {
          this.setState({ detail: data[0] });
        } else {
          this.props.render.history.push("/services");
        }
      });
  };

  render() {
    return (
      <StyledCandidateDetail flexDirection={"column"}>
        {this.state.detail && (
          <NanoFlex className="resultDetails" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            <NanoFlex className="topInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="titleWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.p>
                  <b>{this.translation.global.candidateDetails}</b>
                </Type.p>
              </NanoFlex>
              <NanoFlex className="labelResult" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.p>
                    <b>{this.state.detail.id}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                  <Location />
                  <Type.p>{this.state.detail.cityPreference?.join(", ") ?? "-"}</Type.p>
                </NanoFlex>
                <NanoFlex className="infoWrapper iconInfo" justifyContent="flex-start">
                  <Job />
                  <Type.p>{this.state.detail.professionalExperience ?? "-"}</Type.p>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="detailsResult" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                {this.state.detail.externalObservations && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    {this.state.detail.externalObservations.split("\n").map((s) => (
                      <Type.p>{s}</Type.p>
                    ))}
                  </NanoFlex>
                )}
                {/* {this.state.detail.professionalExperience && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <b>{this.translation.global.experience}</b>
                    </Type.h6>
                    {this.state.detail.professionalExperience && <Type.p>{this.state.detail.professionalExperience}</Type.p>}
                  </NanoFlex>
                )} */}
                {this.state.detail.experience && this.state.detail.experience?.period > 0 && this.state.detail?.experience?.periodType?.name && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <b>{this.translation.global.professionalExperienceTime}</b>
                    </Type.h6>
                    <Type.p>
                      {this.state.detail.experience.period} {this.state.detail.experience.periodType.name}
                    </Type.p>
                  </NanoFlex>
                )}
                {this.state.detail.salaryRangePreference && (this.state.detail.salaryRangePreference.min > 0 || this.state.detail.salaryRangePreference.max > 0) && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <b>{this.translation.global.salary}</b>
                    </Type.h6>
                    <Type.p>{`${this.state.detail.salaryRangePreference.min} - ${this.state.detail.salaryRangePreference.max} ${this.state.detail.isLiquidSalary ? "Líquidos" : "Brutos"}`}</Type.p>
                  </NanoFlex>
                )}
                {this.state.detail.habilitation?.length > 0 && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <b>{this.translation.global.qualification}</b>
                    </Type.h6>
                    {this.state.detail.habilitation.courseName && <Type.p>{this.state.detail.habilitation.courseName}</Type.p>}
                    {this.state.detail.habilitation.degree?.name && <Type.p>{this.state.detail.habilitation.degree.name}</Type.p>}
                  </NanoFlex>
                )}
                {this.state.detail.profiles?.length > 0 && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <b>{this.translation.global.profile}</b>
                    </Type.h6>
                    {this.state.detail.profiles.map((r) => (
                      <Type.p key={r}>{r}</Type.p>
                    ))}
                  </NanoFlex>
                )}
                {this.state.detail.areas?.length > 0 && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <b>{this.translation.global.area}</b>
                    </Type.h6>
                    {this.state.detail.areas.map((r) => (
                      <Type.p key={r}>{r}</Type.p>
                    ))}
                  </NanoFlex>
                )}
                {this.state.detail.skills?.length > 0 && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <b>{this.translation.global.specialization}</b>
                    </Type.h6>
                    {this.state.detail.skills.map((r) => (
                      <Type.p key={r}>{r}</Type.p>
                    ))}
                  </NanoFlex>
                )}
                {this.state.detail.languages?.length > 0 && (
                  <NanoFlex className="detailsWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h6>
                      <b>{this.translation.global.languages}</b>
                    </Type.h6>
                    {this.state.detail.languages.map((r) => (
                      <Type.p key={`${r.language.name}_${r.level.name}`}>
                        {r.language.name} {r.level.name}
                      </Type.p>
                    ))}
                  </NanoFlex>
                )}
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="bottomInfo" justifyContent="space-between">
              <NanoFlex className="dateWrapper" justifyContent="flex-start">
                <Type.h6>
                  {this.translation.global.publishedin} {moment(this.state.detail.publishedDate).format(Settings.moment.displayDate)}
                </Type.h6>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledCandidateDetail>
    );
  }
}
