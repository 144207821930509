import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import AboutIMG01 from "./../../Global/images/aboutIMG01.jpg";
import AboutIMG02 from "./../../Global/images/aboutIMG02.jpg";

// Componets
import { CTAButton } from "./../../Buttons/CTAButton.js";

const StyledAboutBlock = styled(NanoFlex)`
  margin-top: 64px;
  background: linear-gradient(344deg, #ffffff 40%, #e7f3fe 40%);
  .aboutAsideLeft {
    width: 392px;
    margin-right: -48px;
    position: relative;
    z-index: 1;
    .titleWrapper {
      height: auto;
      padding: 32px 80px 32px 16px;
      h4 {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.main.blue};
          font-weight: bold;
          line-height: 42px;
          em {
            color: ${(props) => props.theme.color.colorsPalette.lightOrange};
            font-size: 52px;
          }
        }
    }
    .imgWrapper {
      height: 50vh;
      width: 100%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .aboutAsideRight {
    width: 392px;
    margin-top: -32px;
    .imgWrapper {
      height: 50vh;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .articleWrapper {
      height: auto;
      padding: 32px 16px 32px 80px;
      .ctaWrapper {
        margin-top: 16px;
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-top: 32px;
    .aboutAsideRight {
    margin-top: -16px;
  }
  }

  @media only screen and (max-width: 740px) {
    flex-direction: column;

    .aboutAsideLeft {
      margin-right: initial;
      .titleWrapper {
        padding: 16px;
        h4 {
          font-size: 32px;
          em {
            font-size: 42px;
          }
        }
      }
      .imgWrapper {
        display: none;
      }
    }
    .aboutAsideRight {
      .imgWrapper {
        height: 30vh;
      }
      .articleWrapper {
        padding: 16px;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .aboutAsideLeft {
      width: 90%;
      .titleWrapper {
        h4 {
          font-size: 20px;
          line-height: 30px;
          em {
            font-size: 30px;
          }
        }
      }
    }
    .aboutAsideRight {
      width: 90%;
    }
  }
`;

export default class AboutBlock extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledAboutBlock className="aboutBlock">
        <NanoFlex className="aboutAsideLeft" flexDirection="column">
          <NanoFlex className="titleWrapper">
            <Type.h4 dangerouslySetInnerHTML={{ __html: this.translation.homepage.about.aboutTile }}></Type.h4>
          </NanoFlex>
          <NanoFlex className="imgWrapper">
            <img src={AboutIMG01} alt="About Image" />
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="aboutAsideRight" flexDirection="column" justifyContent="flex-start">
          <NanoFlex className="imgWrapper">
            <img src={AboutIMG02} alt="About Image" />
          </NanoFlex>
          <NanoFlex className="articleWrapper" flexDirection="column">
            <Type.h5>{this.translation.homepage.about.aboutArticle}</Type.h5>
            <NanoFlex className="ctaWrapper" justifyContent="flex-start">
              <CTAButton small link={"#contacts"} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledAboutBlock>
    );
  }
}
