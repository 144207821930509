import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

// Componets
import HeaderServices from "../HeaderServices.js";
import ListBlock from "./ListBlock.js";

//Imgs
import ConsultingRHHeader from "./../../../Global/images/consultingRHHeader.jpg";

const StyledConsultingRH = styled(NanoFlex)`
  height: auto;

  .headerServices {
    .headerWrapper {
      .headerInfo {
        background-color: ${(props) => props.theme.color.brandingColors.brandBrown};

        @media only screen and (max-width: 1074px) {
          background-color: transparent;
          position: absolute;
        }
      }

      .headerImg {
        @media only screen and (max-width: 1074px) {
          width: 100%;
          height: 79vh;

          img {
            object-position: top;
          }
        }
      }

      .ctaContainer {
        display: none;
      }
    }
  }
`;

export default class ConsultingRH extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledConsultingRH flexDirection={"column"}>
        <HeaderServices changeOrder orange title={this.translation.services.consultingRH.header.title} article={this.translation.services.consultingRH.header.article} src={ConsultingRHHeader} />
        <ListBlock />
      </StyledConsultingRH>
    );
  }
}
