import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";

// Componets
import ServicesNavBar from "./ServicesNavBar.js";

const StyledLayoutServices = styled(NanoFlex)`
  height: auto;
`;

export default class LayoutServices extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return <StyledLayoutServices flexDirection={"column"}>{React.cloneElement(this.props.children, { render: this.props.render })}</StyledLayoutServices>;
  }
}
