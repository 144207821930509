import { BaseService } from "./BaseService";
import { getUrlParams } from "../components/Global/tools.js";

export class CandidatesService extends BaseService {
  getWebsiteCandidates(filter) {
    return this.request(`Candidates/GetWebsiteCandidates?${getUrlParams(filter)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  contactUsCompanyEmail(model) {
    return this.request("Candidates/ContactUsCompanyEmail", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }
}

const candidatesService = new CandidatesService();

export default candidatesService;
