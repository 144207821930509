import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

// Componets
import HeaderServices from "../HeaderServices.js";
import AboutBlock from "./AboutBlock.js";

//Imgs
import OutsourcingHeader from "./../../../Global/images/outsourcingHeader.jpg";

const StyledOutsourcing = styled(NanoFlex)`
  height: auto;

  .headerServices {
    .headerWrapper {
      .headerInfo {
        background-color: ${(props) => props.theme.color.brandingColors.brandGreen};

        @media only screen and (max-width: 1074px) {
          background-color: transparent;
          position: absolute;
        }
      }

      .headerImg {
        @media only screen and (max-width: 1074px) {
          width: 100%;
          height: 79vh;

          img {
            object-position: top;
          }
        }
      }
    }
  }
`;

export default class Outsourcing extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledOutsourcing flexDirection={"column"}>
        <HeaderServices title={this.translation.services.outsourcing.header.title} article={this.translation.services.outsourcing.header.article} src={OutsourcingHeader} contactUrl={"https://elevus.tech/"} />
        <AboutBlock />
      </StyledOutsourcing>
    );
  }
}
