import React from "react";
import styled from "styled-components";
import NanoFlex from "../Global/NanoFlex.js";

const StyledSimpleButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  margin: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  .buttonWrapper {
    min-width: 264px;
    height: 42px;
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    text-transform: uppercase;
    padding: 12px ​24px 8px 24px;
    border-radius: 50px;
    border: none;
    border: 0;
    text-transform: uppercase;
    color: ${(props) => (props.disabled ? props.theme.color.main.font : props.theme.color.main.white)};
    font-size: 16px;
    font-weight: bold;
    transition: all 0.4s ease-in-out;
    background-color: ${(props) => (props.disabled ? props.theme.color.colorsPalette.lightGrey : props.orange ? props.theme.color.colorsPalette.lightOrange : props.theme.color.colorsPalette.blue)};

    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline: 0;
    }
  }
`;

const SimpleButton = (props) => {
  return (
    <StyledSimpleButton className="button" orange={props.orange} disabled={props.disabled}>
      <button className="buttonWrapper" type={props.type} onClick={() => props.clickAction(props.id)}>
        {props.label}
      </button>
    </StyledSimpleButton>
  );
};

// Set default props
SimpleButton.defaultProps = {
  id: 0,
  label: "",
  type: "submit",

  clickAction: () => null,
};

export default SimpleButton;
