import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import Benefits from "./../../Global/images/benefits.jpg";
import { ReactComponent as Plus } from "./../../Global/icons/plus.svg";

const StyledBenefitsCareers = styled(NanoFlex)`
  height: auto;
  margin-bottom: 64px;
  .benefitsWrapper {
    height: 800px;
    .asideBenefitsList {
      height: auto;
      background-color: ${(props) => props.theme.color.main.blue};
      padding: 48px 32px;
      width: 60%;
      left: 0;
      z-index: 2;
      position: relative;
      .titleBenefits {
        margin-bottom: 32px;
        h3 {
          color: ${(props) => props.theme.color.main.white};
          text-transform: uppercase;
        }
        h4 {
          color: ${(props) => props.theme.color.main.white};
          text-transform: uppercase;
        }
      }
      .benefitsList {
        .itemBenefit {
          margin-bottom: 24px;
          .iconWrapper {
            width: auto;
            margin-right: 16px;
            svg {
              height: 24px;
              width: auto;
            }
          }
          .infoWrapper {
            h5 {
              color: ${(props) => props.theme.color.main.white};
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .asideImg {
      position: absolute;
      z-index: -1;
      right: 0;
      overflow: hidden;
      height: 800px;
      width: 45%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right;
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
    .benefitsWrapper {
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      .asideBenefitsList {
        width: 100%;
        background: linear-gradient(rgba(0, 39, 91, 0.6), rgba(0, 39, 91, 0.8)), url(${Benefits});
        background-position: right;
        background-size: cover;
      }
      .asideImg {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .benefitsWrapper {
      .asideBenefitsList {
        padding: 24px 16px;
      }
    }
  }
`;

export default class BenefitsCareers extends BaseComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledBenefitsCareers className="benefitsCareers">
        <NanoFlex className="benefitsWrapper" justifyContent="space-between">
          <NanoFlex className="asideBenefitsList" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="titleBenefits" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <Type.h3>
                <b>{this.translation.careers.benefits.title}</b>
              </Type.h3>
              <Type.h4>
                <b>{this.translation.careers.benefits.subtitle}</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="benefitsList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item1}</Type.h5>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item2}</Type.h5>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item3}</Type.h5>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item4}</Type.h5>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item5}</Type.h5>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item6}</Type.h5>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item7}</Type.h5>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item8}</Type.h5>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM */}
              <NanoFlex className="itemBenefit">
                <NanoFlex className="iconWrapper">
                  <Plus />
                </NanoFlex>
                <NanoFlex className="infoWrapper" justifyContent="flex-start">
                  <Type.h5>{this.translation.careers.benefits.list.item9}</Type.h5>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="asideImg">
            <img src={Benefits} alt="Benefits" />
          </NanoFlex>
        </NanoFlex>
      </StyledBenefitsCareers>
    );
  }
}
