import React from "react";
import styled from "styled-components";
import BaseComponent from "../BaseComponent.js";
import NanoFlex from "../Global/NanoFlex.js";
import Type from "../Global/Typography.js";

// Componets
import SelectBtn from "../Buttons/SelectBtn.js";

const StyledSelectBtnsBlock = styled(NanoFlex)`
  margin-bottom: 64px;
  .imgWrapper {
    height: auto;
    width: 25vw;
    min-width: 400px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .titleImg {
      position: absolute;
      bottom: 24px;
      left: 24px;
      height: auto;
      width: auto;
      h2 {
        text-transform: uppercase;
        b {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
  }
  .articlesBlock {
    height: auto;
    margin-top: 3%;
    .articlesBlockWrapper {
      background-color: ${(props) => props.theme.color.colorsPalette.lightBlue};
      padding: 48px;
      height: auto;
    }
    .titleImg {
      display: none;
      margin-bottom: 24px;
      h3 {
        text-transform: uppercase;
        text-align: center;
        b {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
    .btnsContainer {
      height: auto;
    }
    .articleContainer {
      padding: 0;
      .articleSelectedWrapper {
        margin-top: 32px;
        h5 {
          color: ${(props) => props.theme.color.main.font};
          line-height: 36px;
          text-align: center;

          &:last-child {
            margin-bottom: 0;
          }
          em {
            font-size: 50px;
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1692px) {
    .articlesBlock {
      .btnsContainer {
        padding: 0px 15%;
      }
    }
  }
  @media only screen and (max-width: 1074px) {
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 1000px) {
    .imgWrapper {
      display: none;
    }
    .articlesBlock {
      margin-top: 0;
      .titleImg {
        display: flex;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .articlesBlock {
      margin-top: 0;
      .articlesBlockWrapper {
        padding: 32px;
      }
      .titleImg {
        display: flex;
      }
      .articleContainer {
        padding: 0;
      }
    }
  }
`;

export default class SelectBtnsBlock extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
    };
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledSelectBtnsBlock className="selectBtnBlock" justifyContent="stretch" alignItems="stretch" alignContent="stretch">
        <NanoFlex className="imgWrapper" alignItems="flex-end">
          <img src={this.props.src} alt="image" />
          <NanoFlex className="titleImg">
            <Type.h2>
              <b>{this.props.title}</b>
            </Type.h2>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="articlesBlock" alignItems="stretch">
          <NanoFlex className="articlesBlockWrapper" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="titleImg">
              <Type.h3>
                <b>{this.props.title}</b>
              </Type.h3>
            </NanoFlex>
            <NanoFlex className="btnsContainer" flexWrap="wrap" justifyContent="space-around">
              {this.props.options.map((item, i) => (
                <SelectBtn
                  key={i}
                  selected={i == this.state.selectedIndex}
                  label={item.title}
                  clickAction={() => {
                    this.setState({ selectedIndex: i });
                  }}
                />
              ))}
            </NanoFlex>
            <NanoFlex className="articleContainer">
              <NanoFlex className="articleSelectedWrapper" flexDirection="column" alignItems="center">
                {this.props.options[this.state.selectedIndex]?.values?.map((val, i) => (
                  <Type.h5 key={i}>{val}</Type.h5>
                ))}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledSelectBtnsBlock>
    );
  }
}
